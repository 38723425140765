import addZero from "./addZero";
export const timeConversion = (ms, DAY) => {
  const getDay = DAY.getDate();
  const getDayMill = DAY.getTime();

  const day = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][DAY.getDay()];
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][DAY.getMonth()];
  ///difference
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysms = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = ms % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  const minutesms = ms % (60 * 1000);
  const sec = Math.floor(minutesms / 1000);
  ///date
  const Days = Math.floor(getDayMill / (24 * 60 * 60 * 1000));
  const Daysms = getDayMill % (24 * 60 * 60 * 1000);
  const Hours = Math.floor(Daysms / (60 * 60 * 1000));
  const Hoursms = getDayMill % (60 * 60 * 1000);
  const Minutes = Math.floor(Hoursms / (60 * 1000));
  const Minutesms = getDayMill % (60 * 1000);
  const Sec = Math.floor(Minutesms / 1000);

  if (days >= 7) {
    return ` ${` ${addZero(month)} ${getDay} at ${addZero(Hours)}:${addZero(
      Minutes
    )}`} `;
  } else if (days > 0) {
    return `${
      days === 1
        ? `Yesterday at ${addZero(Hours)}:${addZero(Minutes)}`
        : `${day} at ${addZero(Hours)}:${addZero(Minutes)}  `
    } `;
  } else if ((days == 0 && hours != 0 && hours < 10) || hours >= 10) {
    return ` ${
      hours == 1 ? `${addZero(hours)} hour ago` : `${addZero(hours)} hours ago`
    }`;
  } else if (minutes != 0 && minutes < 10 && hours == 0 && days == 0) {
    return ` ${
      minutes == 1
        ? `${addZero(minutes)} minute ago`
        : ` ${addZero(minutes)} minutes ago`
    }`;
  } else if (minutes > 0 && hours == 0 && days == 0) {
    return ` ${
      minutes == 1 ? "just Now " : ` ${addZero(minutes)} minutes ago`
    }`;
  } else if (sec >= 0 && minutes == 0 && hours == 0 && days == 0) {
    return ` ${minutes < 10 ? "just now" : ` ${addZero(sec)}"seconds ago"`}`;
  } else return "undefined";
};

const setTextForm = (text) => {
  return (
    text.charAt(0).toUpperCase() +
    (text.includes("hr")
      ? text.slice(1, text.indexOf("hr")) +
        "HR " +
        text.slice(text.indexOf("hr") + 3)
      : text.slice(1))
  );
};

export default setTextForm;

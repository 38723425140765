export const durationLeaves = (arr, type) => {
  const arrFiltred = arr?.filter((lv) => lv?.type?.name == type);
  let duration = 0;
  let OneDay = 86400000;
  return arrFiltred?.length !== 0
    ? arrFiltred?.map((lv) => {
        const dateStart = new Date(lv.dateStart);
        const dateEnd = new Date(lv.dateEnd);
        const diffTime = Math.abs(dateEnd - dateStart) + OneDay;
        duration = duration + diffTime / (1000 * 60 * 60 * 24);
        return duration;
      })
    : (duration = "0");
};

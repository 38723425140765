import React from "react";
import SickIcon from "../../src/assets/icons/sickLeave.png";
import CasualIcon from "../../src/assets/icons/CasualLeave.png";
import personalIcon from "../../src/assets/icons/PersonalLeave.png";
import VacationIcon from "../../src/assets/icons/leaveVacation.png";

const getLeaveType = (leaveType) => {
  return leaveType === "Sick"
    ? { icon: SickIcon, className: "sick-leave", color: "#f45158" }
    : leaveType === "Casual"
    ? { icon: CasualIcon, className: "casual-leave", color: "#784df5" }
    : leaveType === "Personal"
    ? { icon: personalIcon, className: "personal-leave", color: "#0560fc" }
    : leaveType === "Vacation"
    ? { icon: VacationIcon, className: "vacation-leave", color: "#f18541" }
    : { icon: "", className: "" };
};

export default getLeaveType;

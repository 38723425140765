const findLocation = (path) => {
  let title = (path.charAt(1).toUpperCase() + path.slice(2)).includes("-")
    ? path.charAt(1).toUpperCase() + path.slice(2).replace("-", " ")
    : path.charAt(1).toUpperCase() + path.slice(2);

  title = path.includes("-")
    ? title.substring(0, path.indexOf("-")) +
      path.charAt(path.indexOf("-") + 1).toUpperCase() +
      title.substring(path.indexOf("-") + 1)
    : title;

  return path === "/" ? "Dashboard" : title;
};

export default findLocation;

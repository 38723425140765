const getAccess = (
  permissions,
  permissionGroups,
  functionToAccess,
  methodToAccess
) => {
  return (
    //test by permission
    permissions?.filter(
      (el) =>
        el?.function?.localeCompare(functionToAccess) === 0 &&
        el?.method?.localeCompare(methodToAccess) === 0
    ).length > 0 ||
    // test by permission group

    permissionGroups?.filter(
      (el) =>
        el?.function?.localeCompare(functionToAccess) === 0 &&
        el?.method?.localeCompare(methodToAccess) === 0
    ).length > 0
  );
};
export default getAccess;

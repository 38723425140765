import React from "react";

const getStatus = (status) => {
  return status === "waiting for technical approval"
    ? {
        icon: <div className="status-icon technical-approval-icon"></div>,
        className: "leave technical-approval",
      }
    : status === "waiting for hr approval"
    ? {
        icon: <div className="status-icon hr-approval-icon"></div>,
        className: "leave hr-approval",
      }
    : status === "rejected"
    ? {
        icon: <div className="status-icon rejected-status-icon"></div>,
        className: "leave rejected-status",
      }
    : status === "approved"
    ? {
        icon: <div className="status-icon approved-status-icon"></div>,
        className: "leave approved-status",
      }
    : status === "waiting for employee negotiate"
    ? {
        icon: <div className="status-icon negotiation-status-icon"></div>,
        className: "leave negotiation-status",
      }
    : status === "Full-time"
    ? { icon: "", className: "job-status full-time" }
    : status === "Part-time"
    ? { icon: "", className: " job-status part-time" }
    : { icon: "", className: "" };
};

export default getStatus;
